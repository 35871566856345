import React from "react";

import PropTypes from "prop-types";

const TextHeader = (props) => {
  const {
    children,
    className,
    idName,
  } = props;

  return (
    <div
      id={idName}
      className={"my-36 lg:my-90 mx-0" && className}
    >
      {children}
    </div>
  );
};

TextHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  idName: PropTypes.string,
};

TextHeader.defaultProps = {
  className: "",
  idName: "",
};

export default TextHeader;
