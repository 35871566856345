import React, { Fragment, useState } from "react";

import { Dialog, Disclosure, Transition } from "@headlessui/react";
import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

import { AddIcon24, FilterIcon24 } from "../../icons";
import Checkbox from "../Checkbox/Checkbox";

const SidebarFilters = ({
  children,
  filters,
  filterState,
  headline,
  onChange,
  searchbar,
}) => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <div className="pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4 m-18">
          <aside>
            <div className="lg:block">
              <form className="space-y-30 divide-gray-200">
                <h2 className="h5 mb-20">{headline}</h2>
                <div className="mr-60">{searchbar}</div>
                {filters.map((section, sectionIdx) => (
                  <div key={section.name} className={sectionIdx === 0 ? null : "pt-10"}>
                    <fieldset>
                      <legend className="h5">{section.name}</legend>
                      <div className="space-y-3 pt-6">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex-col items-center">
                            <Checkbox
                              id={`${section.id}-${optionIdx}-mobile`}
                              label={option.label}
                              name={`${section.id}[]`}
                              defaultValue={option.value}
                              defaultChecked={filterState[section.id]?.includes(option.value)}
                              onChange={onChange}
                            />
                            {option.href && (
                              <a className="text-dark-grey text-small" href={option.href} title={`${t("whatMeans")} ${option.label}?`}>
                                {t("whatMeans")} {option.label}
                              </a>
                            )}
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                ))}
              </form>
            </div>
          </aside>

          {/* Product grid */}
          <div className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
            {/* Replace with your content */}
            { children }
            {/* /End replace */}
          </div>
        </div>
      </div>
    </div>
  );
};

SidebarFilters.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterState: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.array.isRequired,
  headline: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  searchbar: PropTypes.node,
};

SidebarFilters.defaultProps = {
  filterState: null,
  headline: "",
  searchbar: null,
};

export default SidebarFilters;
